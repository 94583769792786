// Importa las bibliotecas y estilos necesarios
import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import '../../components/MainTop/MainTopStyles.css';
import Logo from '../assets/relogo.png';
import Footer from '../footer/Footer';
import { AiOutlineArrowDown } from 'react-icons/ai';
import MySvgMachete from '../svgData/Machete';
import MySvgStar from '../svgData/Estrella';

// Componente MainTop
const MainTop = () => {
  // Referencia al formulario
  const form = useRef();

  // IDs y estados
  const serviceId = 'service_9esb2vn';
  const templateId = 'template_0o9eenc';
  const userId = 'ugiLWys1bve64VeD3';
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [envioExitoso, setEnvioExitoso] = useState(false);
  const [errorEnvio, setErrorEnvio] = useState(null);

  // Manejar clic en Contáctanos
  const handleClick = () => {
    setMostrarFormulario(!mostrarFormulario);
    setEnvioExitoso(false);
    setErrorEnvio(null);
  };

  // Resetea los campos del formulario
  const resetForm = () => {
    form.current.reset();
  };

  // Enviar correo electrónico
  const enviarCorreo = async (e) => {
    e.preventDefault();

    try {
      const response = await emailjs.sendForm(serviceId, templateId, form.current, userId);

      console.log('Correo enviado con éxito!', response);
      setEnvioExitoso(true);
      resetForm();
    } catch (error) {
      console.error('Error al enviar el correo:', error);
      setErrorEnvio('Error al enviar el correo. Por favor, inténtalo de nuevo más tarde.');
    }
  };

  return (
    <div className={`main__container ${mostrarFormulario ? 'form-visible' : ''}`}>
      <div className="romana__logo">
        <img src={Logo} alt="logo" className="logo__image" />
      </div>

      <div className="text__container">
      <p className='text'>
      Estamos en proceso de <span className="highlight">reconstrucción</span> de nuestra web para algo especial.
      <span className='iconosstar'><MySvgMachete /> <MySvgStar /></span></p>    
      </div>

      <div className="contact__container">
        <div className="contact__form">
          <p className='contactus' onClick={handleClick}>
            Contáctanos <AiOutlineArrowDown className='icon__arrow' />
          </p>
          {mostrarFormulario && (
            <form ref={form} onSubmit={enviarCorreo}>
              <label htmlFor="nombre">Nombre:</label>
              <input type="text" id="nombre" name="nombre" required />

              <label htmlFor="apellido">Apellido:</label>
              <input type="text" id="apellido" name="apellido" required />

              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" required />

              <label htmlFor="telefono">Teléfono:</label>
              <input type="tel" id="telefono" name="telefono" required />

              <button type="submit">Enviar</button>
            </form>
          )}
        </div>
      </div>

      {envioExitoso && <p className='enviado'>¡Correo enviado con éxito! </p>}
      {errorEnvio && <p className="error-message">{errorEnvio}</p>}

      <Footer />
    </div>
  );
};

export default MainTop;
