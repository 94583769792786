import React from 'react';
import '../../components/footer/FooterStyles.css';
import { GoMail } from 'react-icons/go';
import { BsInstagram } from 'react-icons/bs';
import { FiPhoneCall } from 'react-icons/fi';

const Footer = () => {
  return (
    <footer>
    <div className='footer__container'>
      <div className="text__contact">
        <h5><a href="mailto:info@romanaebanisteria.com">info@romanaebanisteria.com</a></h5>
        <h6><a href="tel:+18092222480">809-222-2480</a></h6>
      </div>

      <div className="social__media">
        <div className="social__icons">
          <a href="mailto:info@romanaebanisteria.com">
            <GoMail className="icon" size={35} color="white" />
          </a>
          <a href="https://www.instagram.com/romanaebanisteria/" target="_blank" rel="noopener noreferrer">
            <BsInstagram className="icon" size={35} color="white" />
          </a>
          <a href="tel:+809-222-2480">
            <FiPhoneCall className="icon" size={35} color="white" />
          </a>
        </div>
      </div>
    </div>
    </footer>
  );
}

export default Footer;
