import React from 'react';

const MySvgStar = () => {
  const svgData = `
  <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 512.001 512.001"
  style="enable-background:new 0 0 512.001 512.001"
  xml:space="preserve"
  width="32"
  height="32"
>
  <path
    style="fill:#ffdc64"
    d="m264.306 25.651 64.239 154.45 166.741 13.368c7.975.64 11.208 10.592 5.133 15.797l-127.04 108.822 38.813 162.711c1.856 7.783-6.61 13.934-13.438 9.762L256 403.369l-142.753 87.193c-6.828 4.17-15.294-1.981-13.438-9.762l38.813-162.711L11.583 209.266c-6.076-5.204-2.842-15.157 5.133-15.797l166.741-13.368 64.239-154.45c3.072-7.387 13.537-7.387 16.61 0z"
  />
  <path
    style="fill:#ffc850"
    d="M175.924 325.741a26.983 26.983 0 0 0-8.725-27.55l-84.533-71.058c-4.57-4.558-2.137-13.275 3.861-13.835l108.22-10.101a26.98 26.98 0 0 0 22.945-17.908L269.49 38.117l-5.185-12.468c-3.073-7.387-13.538-7.387-16.609 0l-64.239 154.448-166.74 13.37c-7.975.64-11.209 10.592-5.133 15.797l127.04 108.823-38.813 162.71c-1.856 7.783 6.61 13.934 13.438 9.762l22.793-13.922 39.882-150.896zM410.757 488.059l-77.004-70.871c-21.019-19.345-51.353-24.737-77.752-13.82l142.753 87.194c4.401 2.688 9.444 1.059 12.003-2.503z"
  />
  <path
    d="M511.141 197.049a17.379 17.379 0 0 0-15.182-11.986l-161.607-12.955-62.26-149.696a17.38 17.38 0 0 0-16.091-10.734 17.381 17.381 0 0 0-16.091 10.734l-62.261 149.694-161.608 12.957c-7.039.564-12.999 5.27-15.182 11.986s-.126 14.026 5.237 18.62l123.128 105.472-37.617 157.701a17.378 17.378 0 0 0 6.708 18.142 17.377 17.377 0 0 0 19.327.774L256 413.248l138.358 84.509a17.399 17.399 0 0 0 9.087 2.565c3.599 0 7.188-1.12 10.24-3.338a17.38 17.38 0 0 0 6.708-18.142l-37.617-157.701 123.128-105.472a17.379 17.379 0 0 0 5.237-18.62zm-16.208 5.813-127.04 108.823a8.43 8.43 0 0 0-2.717 8.36l38.813 162.711c.047.196.088.365-.216.586-.305.22-.46.125-.623.025l-142.754-87.194a8.429 8.429 0 0 0-8.79 0l-142.753 87.193c-.164.101-.32.197-.624-.025-.304-.22-.263-.389-.216-.586l38.813-162.711a8.432 8.432 0 0 0-2.717-8.36L17.069 202.86c-.146-.126-.284-.244-.169-.6.116-.356.298-.372.49-.387l166.741-13.368a8.43 8.43 0 0 0 7.112-5.167l64.239-154.45c.074-.178.144-.346.519-.346.376 0 .445.169.519.346l64.239 154.45a8.432 8.432 0 0 0 7.112 5.167l166.741 13.368c.192.016.374.03.49.387.117.356-.023.476-.169.602z"
  />
  <path
    d="M168.363 296.155a8.433 8.433 0 0 0-4.426 11.073 27.344 27.344 0 0 1 1.478 17.25l-13.947 58.471a8.433 8.433 0 0 0 6.246 10.159 8.448 8.448 0 0 0 1.964.233 8.436 8.436 0 0 0 8.195-6.478l13.947-58.471c2.231-9.354 1.407-18.972-2.384-27.811-1.835-4.281-6.792-6.26-11.073-4.426zM337.621 208.462l-11.278-.904c-10.345-.829-19.247-7.297-23.233-16.879l-6.619-15.914a8.432 8.432 0 0 0-15.571 6.477l6.619 15.914c6.425 15.449 20.778 25.877 37.455 27.213l11.277.904a8.434 8.434 0 0 0 9.08-7.732 8.43 8.43 0 0 0-7.73-9.079zM281.538 138.813l-7.48-17.985a8.433 8.433 0 0 0-15.571 6.477l7.48 17.985a8.436 8.436 0 0 0 7.789 5.197 8.433 8.433 0 0 0 7.782-11.674z"
  />
</svg>

  `;

  return <div dangerouslySetInnerHTML={{ __html: svgData }} />;
};

export default MySvgStar;
